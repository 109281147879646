@import "../style/variables";
.home {
    &-subnav {
        display: none;
    }
    &-content {
        width: 100%;
        margin: 0 auto;
        padding: 30px 0;
    }
    &-catalog{
        h1 {
            width: 90%;
            font-weight: 400;
            margin: 0;
            position: absolute;
            background-color: $theme_color1;
            max-width: 300px;
            text-align: center;
            border-radius: 6px;
            top: -15px;
            left: 50%;
            padding: 4px 0;
            color: #fff;
            letter-spacing: 1.4px;
            font-size: 18px;
            transform: translateX(-50%);
            // text-transform: uppercase;
        }
        & > div {
            margin-bottom: 30px;
            position: relative;
        }
        &-row {
            display: flex;
            flex-flow: column wrap;
            //margin-bottom: 60px;
            padding: 10px 0;
            border: 1px solid $theme_color1;
            border-radius: 4px;
        }
        &-cover {
            width: 100%;
            max-width: 400px;
            margin-bottom: 20px;
            object-fit: contain;
            margin: 0 auto;
            padding: 0 20px;
        }
        &-subcatalog {
            width: 100%;
            a{
                //width: 48% !important;
            }
            h3 {
                font-size: 16px;
                font-weight: 400;
                // width: 90%;
                margin: 10px auto 0;
                text-align: center;
            }
            img {
                width: 100%;
                height: 120px;
                object-fit: contain;
                margin: 0 auto;
            }

            .slick-dots{
                bottom: -15px;
            }

            .slick-slide > div{
                display: flex;
                justify-content: space-between;
            }
            .slick-prev {
                left: -14px;
                z-index: 1;
            }
            .slick-next {
                right: -20px;
                z-index: 1;
            }
            .slick-prev:before, .slick-next:before {
                color: #000;
            }
        }
    }
}

@media screen and (min-width: 760px){
    .home {
        &-subnav {
         display: block;
        }
        &-content{
            padding: 20px 0;
        }
        &-catalog{
            max-width: none;
            &-row {
                flex-flow: row wrap;
            }
            h1 {
                left: 20px;
                transform: none;
            }
            &-cover {
                width: 40%;
                margin-bottom: 0;
            }
            &-subcatalog {
                width: 60%;
                padding: 0 20px 10px 30px;
                a{
                    //width: 45% !important;
                }
                img {
                    max-width: 120px;
                    max-height: 120px;
                }
                .slick-dots{
                    bottom: -25px;
                }
                .slick-slide div{
                    justify-content: space-between;
                }
                .slick-slide div:nth-child(2) a{
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 800px){
    .home {
        &-subnav {
         display: block;
        }
        &-content{

            padding: 20px 0;
        }
        &-catalog{
            max-width: none;
            &-row {
                flex-flow: row wrap;
            }
            h1 {
                font-size: 22px;
            }
            &-cover {
                width: 50%;
                margin-bottom: 0;
            }
            &-subcatalog {
                width: 50%;
                padding: 0 20px 10px 30px;
                a{
                    //width: 45% !important;
                }
                .slick-dots{
                    bottom: -25px;
                }
                .slick-slide div{
                    justify-content: space-between;
                }
                .slick-slide div:nth-child(2) a{
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 900px){
    .home {
        &-catalog{
            &-subcatalog {
                img {
                    max-width: none;
                    max-height: none;
                    height: 160px;
                }
            }

            &-promotions {
                width: calc(100% - 320px)!important;
                max-width: 900px;
                padding: 4px 10px 0 5px !important;
                a {
                    width: 50%!important;
                    margin-bottom: 0px !important;
                    padding-right: 20px;
                }
                img {
                    //width: 336px;
                    //height: 220px;
                  width: 100%;
                  height: auto;
                    //object-fit: cover;
                }

            }
        }
    }
}

@media screen and (min-width: 1080px){
    .home {
        &-subnav {
         display: block;
        }
        &-content{
            padding: 20px 0;
        }
        &-catalog{
            &-row {
                flex-flow: row wrap;
            }
            &-cover {
                width: 35%;
                margin-bottom: 0;
            }
            &-subcatalog {
                width: 65%;
                padding: 0 20px 10px 30px;
                a{
                    //width: 33.33% !important;
                }
                .slick-dots{
                    bottom: -25px;
                }
                .slick-slide div{
                    justify-content: flex-start;
                }
                .slick-slide div:nth-child(2) a{
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px){
    .home {
        &-subnav {
         display: block;
        }
        &-content{
            padding: 20px 0;
        }
        &-catalog{
            &>div {
                position: relative;
            }
            &-row {
                flex-flow: row wrap;
            }
            h1 {
                width: 100%;
                margin: 0;
            }
            &-cover {
                width: 34%;
            }
            &-subcatalog {
                width: 66%;
                padding: 0 25px 0 50px;
                a{
                    //width: 23% !important;
                }
                .slick-prev {
                    left: -25px;
                }
                .slick-next {
                    right: -25px;
                }
                .slick-slide > div {
                    justify-content: flex-start;
                }
                a {
                    padding: 0 9px 11px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .randomProductName {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.categoryGroupBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 0;

  .categoryGroupNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 8px 0 13px;
  }

  .categoryGroupBlock_btn {
    display: block;
    width: 50%;
    color: #008CB4;
    text-transform: uppercase;

    @media screen and (max-width: 560px) {
      width: 100%;
    }

  }

  .categoryGroupBlock_items {
    width: 100%;
    //height: 150px;
    border-radius: 10px;
    //border: 1px solid #2a4256;
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    //justify-content: center;
    background: #008CB4;

    &.active {
      background: #2a4256;
    }

    h4 {
      margin: 0;
    }

    img {
      width: 40px;
      height: 40px;
      margin: 0 10px 0 0;
    }

    p {
      margin: 0;
    }
  }
}

.promotional_offers {
  width: 100%;
  min-width: 1200px;
  min-height: 375px;
  @media screen and (max-width: 1250px) {
    min-width: 100%;
    width: 100%;

    .home-catalog-subcatalog {
      width: calc(100% - 320px)!important;

      @media screen and (max-width: 780px) {
        width: 100% !important;
      }
    }
  }

  //flex-wrap: nowrap!important;
}
