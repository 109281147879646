.products_new_table_columns {
  text-align: center;
  @include flexbox;
  @include justify-content(flex-start);
  @include flex-wrap(wrap);

  &.MuiGrid-spacing-xs-1 {
    margin-top: 0;
    margin-bottom: 8px;
  }

}


@media (max-width: 1280px) { /*переделать*/
  #progressload {
    left: 45%;
  }
}


@include mdUp {
  div.MuiContainer-root {
    padding-left: ($spacing*2);
    padding-right: ($spacing*2);
  }
}

@include xlUp {
  .MuiContainer-root.MuiContainer-maxWidthXl {
    max-width: $max_width_container;
  }
}

@include smUp {
  .MuiContainer-root.MuiContainer-root.MuiContainer-maxWidthXl {
    padding-left: ($spacing*2);
    padding-right: ($spacing*2);
  }
}


.search_result_container {

  .search_result_bottom_container {
    & > .MuiGrid-spacing-xs-2 {
      margin-top: 0;
      margin-bottom: 0;
    }

  }

  & {
    max-width: 1920px;
  }

  .main_search_container {
    overflow-y: visible;
    position: relative;
    @media (min-width: 1366px) {

      &.MuiGrid-root.MuiGrid-grid-xs-9 {
        max-width: calc((100% / 12) * 10);
        flex-basis: calc((100% / 12) * 10);
      }

    }

  }


  .search_world_container {
    //@include flex_box_vertical_center;
    width: 100%;
    .MuiTypography-root {
      color: hsla(0, 0%, 50%, 1);;
    }
  }

  .button_filter_container {


    & > div {
      width: 100%;
    }
  }

  .sortable_container {

    .sort_form {
      background-color: #fff;
      height: 100%;
      @include flexbox;
      @include flex-direction(row);
      @include flex-wrap(wrap);
      @include align-items(center);
    }

    .sort_form > .MuiInputBase-root {
      height: 100%;
      width: calc(100% - 30px);
    }

    .show_sort_panel_button {

      .text_block {
        display: block;
        @include text-shorten;
        width: calc(100% - 18px);
      }

      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 18px;
      }
    }

  }

  .sort_form {
    height: 28px;
    text-align: center;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin: 0 7px 0 0;

    @include mdUp {
      width: 183px;
    }

    .sort_icon {
      @include transform(rotate(90deg));
    }

    .MuiInputBase-root {
      border: none;
      height: 28px;
      color: $theme_color2;
    }

    .MuiInputBase-input {
      height: 100%;
      box-sizing: border-box;
      padding: 6px 11px;
      padding-right: 29px;
    }

    .MuiSelect-iconOutlined {
      right: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }

    .MuiSvgIcon-root {
      color: $theme_color2;
    }

  }

  .show_filter_button {
    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 18px;
    }

    .text_block {
      width: calc(100% - 18px);
    }
  }

  .mobile_search_control_panel {
    margin-top: 0;
    margin-bottom: 0;
  }


}

.block_type_button_container {


  & > * {
    width: 100%;
    @include flex_align(flex-end, center);
  }
}


.static_page_container {
  padding-top: ($spacing*3);
  padding-bottom: ($spacing*3);
}

.footer {
  background-color: $header_bg_color;

  .MuiButton-label,
  .MuiSvgIcon-root {
    color: #fff;
  }

  .MuiTypography-subtitle2 {
    color: #fff;
  }

  .MuiButton-root {
    width: auto;

    .MuiButton-label {
      border-bottom: 1px solid transparent;
      @include transition();
    }

    &:hover {
      .MuiButton-label {
        border-bottom: 1px solid #fff;
      }
    }

  }

  .MuiButton-root:hover {
    background-color: transparent;

    .MuiTypography-body1 {
      color: #fff;
      @include transition(0.3s);
    }
  }


  h4 {
    color: #f9c533;
    text-transform: uppercase;


    @include mdUp {
      margin-top: 13px;

    }

  }


  .bottom_footer {
    background-color: #232323;
    padding-top: $spacing;
    padding-bottom: $spacing;
    text-align: center;

    .MuiGrid-item {
      padding-top: 0;
      padding-bottom: 0;
    }

  }

  .MuiList-root {
    .MuiButtonBase-root {
      padding-left: 0;
      @include hvr_icon_back;
    }
  }

  .footer_contact_list_2 {
    margin-top: 24px + 13px;
  }

  .footer_schedule_list {

    .MuiListItem-root {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;

    }

    .MuiTypography-body1 {
      color: #fff;
      font-size: 14px;
      line-height: 20px;
    }
  }


  .footer_meni {

    [aria-current="page"] .MuiButton-label {
      color: #fff;
    }

  }

  .MuiPaper-elevation1 {
    @include css3-prefix('box-shadow', none);
  }

  .MuiPaper-root {
    background: transparent;
  }

  .MuiExpansionPanelSummary-root,
  .MuiExpansionPanelDetails-root {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiExpansionPanelSummary-expandIcon {

    .MuiSvgIcon-root {
      color: #fff;
    }

    &.Mui-expanded {
      padding-left: 0;
    }

  }

  .MuiExpansionPanelSummary-root {

    &.Mui-expanded {
      min-height: 48px;
    }

    .MuiIconButton-root {
      padding-right: 0;
      padding-left: 0;
    }

  }

  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .MuiExpansionPanelDetails-root {
    padding-top: 0;
  }

  .MuiExpansionPanel-root {
    &.Mui-expanded {
      margin-top: auto;
      margin-bottom: auto;
    }

    &:before {
      display: none;
    }

    h4 {
      margin-top: 0;
    }

    .footer_contact_list_2 {
      margin-top: 0;
    }

  }


  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }

  .MuiGrid-container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .copyright_container {
    text-align: right;
  }

  .company_container {
    text-align: left;

    & > * {
      @include flexbox;
      @include align-items(flex-end)
    }

    p {
      width: 100px;
    }

    img {
      padding-right: 10px;
      width: 70px;
      display: block;
    }

  }

}

.header {

  .toolbar_main {
    @include flex-wrap(wrap);
  }

  .toggle_search_button {
    color: inherit;
    border: none;
  }

  .search_mobile_panel {
    background-color: transparent;

    .header_search_input {
      @include flex-basis(100%);
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 0;
    }

    .MuiExpansionPanelSummary-root {
      min-height: 0;
    }

    .MuiExpansionPanelSummary-content {
      margin: 0;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0;
    }

    .MuiExpansionPanelDetails-root {
      padding: $spacing 0;
    }

  }

}

.tecdoc_form_container {
  padding-bottom: $spacing*2;
}

.header_bottom .MuiButtonGroup-root {
  width: 100%;
  @include flexbox();
  @include justify-content(space-around);

}


.header_bottom .MuiButton-root {
  @include flex-grow(1);
  border: none;
  border-radius: 0;


  &:hover {
    background: #556d83;
  }

}


.product_buy_block {
  //border-bottom: 1px solid #aaaaaa;
  width: 100%;
  margin: 0 auto;
  @include align-items(center);
  background-color: #fff;
  padding-top: 0;
  padding-left: $spacing;
  padding-right: $spacing;
  //border-radius: 5px;

  @include smUp {
    padding: $spacing*2;
  }

  @include mdUp {
    //padding: $spacing*2 $spacing*2;
    padding: 0 $spacing*2;
    //border: 1px solid #d6d6d6;
  }


  .header {
    @include flex_align(space-between, center);
    margin-bottom: 2px;
  }


  .article {
    color: $theme_color2;
  }

  .description {
    padding-bottom: $spacing;
    border-bottom: 1px solid $border_gray_color;
  }


  $button_width: 36px;
  $button_width_xs: $spacing*4;

  .my_numeric_input {

    .MuiButton-root {
      background-color: #fff;
      border-color: #000;
      color: #000;

      &:hover {
        border-color: #000;
      }
    }

    .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child).stDel {
      border-right-color: #000;
    }

    .MuiSvgIcon-root {
      height: 28px;
    }

    @include xlUp {
      .MuiSvgIcon-root {
        height: 38px;
      }
    }

    .stInput {
      border-color: $borderColorGray;

      &:hover {
        border-color: $borderColorGray;
      }
    }

    .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child).stInput {
      border-right-color: $borderColorGray;
    }

    input {
      width: calc(100% - (#{$button_width_xs} * 2 + 4px));

      @include lgUp {
        width: calc(100% - (#{$button_width} * 2 + 4px));
      }

    }

    .stDel {
      margin-left: 0;
    }

    & > div > * {
      height: $button_width_xs;


      @include lgUp {
        height: $spacing*5;
      }

    }

    & > div > .MuiButton-root {
      width: $button_width_xs;

      @include lgUp {
        width: $button_width;
      }

    }
  }

  .add_to_cart {
    text-align: center;
    width: 100%;
    padding: 4px 16px;
  }

  .add_to_favorite_button {
    padding: 0 $spacing*2;
    background: #ebebeb;
    @include align-items(center);
    @include square(100%);

    .MuiSvgIcon-root {
      font-size: 1.35em;
    }

    @include xlUp {
      background-color: transparent;

      .MuiSvgIcon-root {
        font-size: 1.7em;
        color: $theme_color1;
      }
    }

  }

  .product_input_container > .MuiGrid-container {
    @include align-items(center);
  }
}


.cart_row {

  .my_numeric_input {
    @include justify-content(center);
  }

}

.search_history_popover {
  width: calc(100% - (#{$spacing * 4}));

  &.MuiPaper-root {
    background-color: #f7f7f7;
  }

  @include smUp {
    margin-left: 2px;
    width: calc(100% / 12 * 8 / 12 * 6 - 14px);
  }

  @include mdUp {
    width: calc(100% / 12 * 9 / 12 * 9 - 24px);
    margin-left: 3px;
  }

  @include lgUp {
    width: calc(100% / 12 * 9 / 12 * 9 - 28px);
    margin-left: 2px;
  }

  @include xlUp {
    width: calc(100% / 12 * 10 / 12 * 6 - #{$spacing*2} );
    margin-left: 3px;
  }

  @media (min-width: $max_width_container) {
    width: calc(#{$max_width_container} / 12 * 10 / 12 * 6 - #{$spacing*2} + 2px);
    margin-left: 2px;
  }

}

.header_search_input .search_auto_suggestion {
  $icon_w: 57px;
  right: 0;
  z-index: 1;
  position: absolute;
  margin-top: $spacing;
  width: calc(100% + 39px + #{$spacing*2} + #{$icon_w});
  left: -($spacing + $icon_w);
}


.characteristic_table .MuiTableRow-root {

  .MuiTableCell-root {
    padding-top: 14px;
    padding-bottom: 14px;
    border-color: transparent;
  }

  .title {
    color: #636363;
  }

}

.accordion_container_for_mobile .MuiExpansionPanel-root {

  .MuiTypography-h5 {
    margin-bottom: 0;
    font-weight: normal;
  }

  .MuiExpansionPanelDetails-root {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .MuiExpansionPanelDetails-root {
    padding-bottom: $spacing*2;
  }

  &.Mui-expanded {
    margin-bottom: 0;
    margin-top: 0;
  }

}


.origin_code_table,
.analog_code_table,
.product_features_list {

  .MuiTableCell-root {
    border-color: transparent;
  }

}

.product_page_wrapper {

  .product_page_slider_product {
    width: 310px;
    display: none;

    @media (min-width: 1300px) {
      display: block;
    }

    @media (min-width: 1570px) {
      width: 22vw;
    }

    @media (min-width: 1830px) {
      width: 17vw;
    }

    @media (min-width: 1889px) {
      width: 310px;
    }
  }

  .product_page_container {

    @media (min-width: 1300px) {
      width: auto;
      max-width: 75vw;
      //flex-shrink: 0;
    }

    @media (min-width: 1570px) {
      width: auto;
      max-width: 78vw;
      //flex-shrink: 0;
    }

    @media (min-width: 1830px) {
      width: auto;
      max-width: 65vw;
      //flex-shrink: 0;
    }

    @media (min-width: 1890px) {
      width: 100%;
      max-width: 1280px;
      //flex-shrink: 0;
    }

  }

  .product_page_slider_promo {
    display: block;

    @media (max-width: 1840px) {
      display: none;
    }
  }

}

.product_page_container {
  width: 100%;
  max-width: 1280px;
  height: auto;
  margin: 0 auto;
  //width: auto;
  padding: 0 15px;
  flex-shrink: 0;
  //flex-shrink: 0;


  //@media (max-width: 1680px) {
  //  width: 100%;
  //  max-width: 62vw;
  //  //flex-shrink: 0;
  //}
  //
  //@media (max-width: 1890px) {
  //  width: 100%;
  //  max-width: 67vw;
  //  flex-shrink: 0;
  //}


  .product_details_container.MuiGrid-item {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    @include mdUp {
      padding-left: $spacing;
      padding-right: $spacing;
    }

  }

  .accordion_container_for_mobile .MuiGrid-item {
    padding-top: 0;
  }

  .available_list {
    margin: 0;
    padding: 0 0 0 48px - $spacing*4;
    list-style: none;
    line-height: 1.6;
  }

  .MuiTable-root {
    border-color: transparent;
    @include align-items(center);
  }

  @include mdUp {
    .product_slider_container.MuiGrid-item {
      padding-bottom: 0;
    }
  }

  .product_base_info_container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .analog_container .MuiTypography-root {
    margin-bottom: 0;
  }

  .analog_container {

    .MuiTypography-h5 {
      text-transform: uppercase;
      color: $theme_color2;
      font-weight: 500;
    }

  }

  .breadcrumbs_container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .product_slider_container {
    position: relative;

    &.MuiGrid-item {
      padding-left: 0;
      padding-right: 0;
    }

    @include mdUp {

      &.MuiGrid-item {
        padding-left: $spacing;
        padding-right: $spacing;
      }

    }

  }

  .back_button {
    .MuiSvgIcon-root {
      color: $theme_color2;
      font-size: 1.25rem;
    }
  }

  .product_page_header {

    p {
      margin-bottom: 0;
      margin-top: 0;
    }

    .article {
      color: $theme_color2;
    }

  }

  .analog_in_stock {
    margin-top: 16px;
  }

  .analog_in_stock .MuiTypography-h5 {
    text-transform: uppercase;
    color: $theme_color2;
    font-weight: 500;
    padding-bottom: 14px;
    border-bottom: 2px solid $theme_color2;
    vertical-align: super;
    display: inline-flex;
    margin-bottom: -4px;
  }

  .analog_in_stock .products_new_table_columns {
    margin-bottom: 0;
  }

  .features_block_container {
    margin-top: 4px;
    margin-bottom: 0;
    padding-left: $spacing;
  }

  .existence {
    padding-top: $spacing*2;
  }

  .packing_container, .ball_icon, .bob_icon {
    text-align: center;
  }

  .packing_container .MuiSvgIcon-root {
    font-size: 300px;
    max-width: 100%;
    width: 300px;
    height: 300px;
  }

  .ball_icon .MuiSvgIcon-root {
    font-size: 9rem;
    max-width: 100%;
  }

  .bob_icon .MuiSvgIcon-root {
    font-size: 6rem;
    max-width: 100%;
  }

}


.product_tab {
  height: 620px;
  position: relative;
  overflow: hidden;

  @include lgUp {
    height: 596px;
  }

  @include xlUp {
    height: 566px;
  }

  @media (min-width: 1280px) {
    height: 480px;
  }

  .MuiTabs-flexContainer {
    border: none;
  }

  .MuiTabs-root {
    min-height: 33px;
  }

  .description_block {
    padding-top: 14px;
    padding-bottom: $spacing*2;
  }

  .MuiTabs-indicator {
    //background-color: transparent;
  }

  .MuiTabs-flexContainer {
    position: relative;
    border-bottom: 1px solid #aaa;
    width: calc(100% - 1px);
  }
}

.analog_lists_container.MuiGrid-spacing-xs-2 {
  margin-bottom: 0;
  margin-top: 0;

  .MuiTypography-h5 {
    padding-left: $spacing*4;
    padding-right: $spacing*4;

    @include mdUp {
      padding-left: 0;
      padding-right: 0;
    }
  }


  & > .MuiGrid-item {
    padding-left: 0;
    padding-right: 0;

    @include mdUp {
      padding-left: $spacing;
      padding-right: $spacing;
    }

  }

}

.analogs_list {
  padding-bottom: $spacing;
  margin-left: -$spacing;
  margin-right: -$spacing;

  .slick-track {
    margin-left: 0;
  }

  .slick-slider .slick-arrow:hover {
    @include opacity(1);
  }

  .slick-prev {
    left: 0;

    img {
      fill: $theme_color2;
    }

    .arrow {
      fill: $theme_color2;
    }
  }

  .slick-dots {
    left: 0;
  }

  .slick-next {
    right: 0;
  }


  .slick-slider {
    margin-bottom: 24px;
    padding-left: $spacing*4;
    padding-right: $spacing*4;

    @include smUp {
      padding-left: 0;
      padding-right: 0;
    }

    .slick-arrow {
      @include square(30px);
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      @include transition();

      &:hover {
        @include opacity(1);
      }

      &:before {
        display: none;
        color: #000;
      }
    }
  }
}


.profile_user_info {
  padding-bottom: $spacing*3;
}

.static_page_container {
  img {
    max-width: 100%;
  }

}

.about_brand_page {

  .back {

    .MuiTypography-body2 {
      color: $theme_color1;

    }

    .MuiSvgIcon-root {
      font-size: 2.4em;
      color: $theme_color1;
    }
  }

  .left_info_panel {

    .MuiSvgIcon-root {
      color: $theme_color1;
      font-size: 1.8em;
    }

  }

  .description_block {
    padding-left: $spacing*3;

    .MuiTypography-h4 {
      color: $darkBlueColor;
      font-weight: normal;
    }

    .header_container {
      @include flex_align(space-between, unset);

      .MuiLink-root {
        color: $theme_color1;
      }

    }

    .description_text {
      color: $darkBlueColor;
    }

    .search_button {
      font-size: 14px;
      text-transform: uppercase;
      margin-top: $spacing*4;
      padding-right: 60px;
      padding-left: 60px;
    }
  }


  .MuiGrid-item.back_button_container {
    padding-top: $spacing*4;
    padding-bottom: $spacing*4;
  }

}

.category_container {
  padding-bottom: 5px;
  padding-top: 0;
}

.tree_catalog {
  padding-bottom: $spacing*2;
  padding-top: 0;

  .button_to_back {


    .MuiSvgIcon-root {
      font-size: 2.3em;
    }
  }

  .MuiListSubheader-root {
    padding-right: 0;
    padding-left: 0;

    .MuiButton-startIcon {
      margin-left: -12px;
    }

    .MuiButton-root {
      padding-left: $spacing*2;
      width: 100%;
      padding-top: 0;
      text-align: left;
      padding-bottom: 0;

      .MuiButton-label {
        @include justify-content(end);
      }


    }

    .MuiTypography-body1 {
      margin-bottom: $spacing;
    }

  }

  .MuiDivider-root {
    margin-bottom: 14px;
  }

}

.sidebar_left_container {
  $sidebar_w: 270px;
  width: $sidebar_w;

  &.MuiPaper-root {
    background-color: $textColorGray;
  }


  .MuiDivider-root {
    background-color: $border_gray_color;
    height: 1px;
  }


  h6 {
    font-weight: bold;
    color: $theme_color1;
    padding-left: $spacing*2;
    padding-right: $spacing*2;
  }

  .header {
    color: white;
    height: auto;
    padding: $spacing $spacing*2;
    z-index: 1;
    @include box-shadow(0, 2px, 3px, grey);
    background-color: #f9c533;
    margin-bottom: $spacing*2;
  }

  .sidebar_button_close {
    top: 6px;
    left: $sidebar_w;
    color: white;
    position: fixed;
  }

  .company_name_block {
    color: black;
    margin-left: 12px;
    overflow: hidden;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
  }

}

.filter_container {
  //height: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 990px) {
    display: none !important;
  }

  @media (min-width: 1366px) {

    &.MuiGrid-root {
      max-width: calc((100% / 12) * 2);
      flex-basis: calc((100% / 12) * 2);
    }

  }

  .MuiSlider-root {
    color: $theme_color2;
  }

  .filter_header {
    @include flex-wrap(nowrap);
    @include flex_align(space-between, flex-start);
  }

}

.cart_table_container {
  .cart_header .button_container {
    text-align: right;
  }
}

.cart_columns_container {

  .cart_header .button_container {
    text-align: right;
  }

}

.search_result_product_table {
  border: 1px solid rgb(238, 238, 238);
  background-color: white;
}

.cart_product_table {
  border: 1px solid rgb(238, 238, 238);
  background-color: white;
}

.add_to_cart_dialog {

  .MuiDialogTitle-root {
    padding: 12px 12px 0;
  }

  .MuiDialogContent-root {
    padding: 0 12px;
  }

  .MuiDialogActions-root {
    padding: 12px;

    & > * {
      display: block;
      width: 100%;
    }
  }

  .close {

    .MuiSvgIcon-root {
      border-radius: 50%;
      background-color: $border_gray_color2;
      color: #fff;
    }

  }

}


.breadcrumbs_container {
  padding-top: $spacing;
}


.sign_in_container,
.registration_form,
.sign_message_container,
.confirm_email_container,
.forgot_password_container,
.confirm_password_container {

  .form {
    margin: 0 auto;
    padding: 10px $spacing;
    background: #fff;


    @include xlUp {
      max-width: 416px;
    }
  }

  .contentBody {
    margin-top: 20px;
  }

  .footer_login {
    margin-top: $spacing*2;
    background-color: $theme_color1;
    color: $textColorGray;
    flex-flow: column wrap;
    font-size: 12px;
    @include flex_box_vertical_center;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .MuiSelect-icon {
      fill: $textColorGray;
    }

  }

  .title {
    color: #000;
    font-size: 26px;
    margin-top: 24px;
    text-align: center;
    font-weight: 400;
    margin-bottom: $spacing*2;
  }

  hr {
    margin: 0 0 1em;
  }

  .footer_link {
    a {
      color: #fff;
    }
  }

}


.cart_page .cart_container {
  display: block;
}

.registration_form {

  .checkbox_container {
    padding: 0 4px 9px 2px;
    flex-flow: row wrap;
    font-size: 14px;
    @include flex_box_vertical_center;


    .checkbox_block {
      padding: 9px 4px 9px 2px;
      flex-flow: row wrap;
      font-size: 14px;
      @include flex_box_vertical_center;
    }

    .checkbox_text {
      margin-top: 0;
      text-align: left;
      margin-left: $spacing;
    }

    a {
      text-decoration: none;
      color: #006ce5;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.mobile_search_container {
  padding-right: $spacing*2;
}

.order_container {

  .data_filter_container {
    @include flex-wrap(wrap);
    @include flexbox;
    @include align-items(baseline);


    & > p {
      width: 100%;
    }

    .MuiInput-input {
      color: $theme_color2;
    }

    @include mdUp {

      @include justify-content(flex-end);

      & > p {
        width: auto;
      }

    }

  }


  .order_table_paper {
    background-color: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.42);
  }

  .order_table {
    background: lighten(#E8E8E8, 3%);

    .MuiTableCell-stickyHeader {
      background-color: $border_gray_color;
    }

    .MuiTableHead-root {

      .MuiTableCell-root {
        padding: $spacing $spacing*2;
      }
    }

  }
}

.search_drawer .search_auto_suggestion {
  position: fixed;
  width: calc(100% - (70px + (#{$spacing} * 2)));
  left: 70px;
}

.my_account_header {

  .search_auto_suggestion {
    right: 0;
    z-index: 1;
    position: absolute;
    margin-top: $spacing;
    left: -7px;
  }
}

.cart_popover {
  margin-left: 6px;
  margin-top: 8px;
}

.filter_sidebar {
  width: 270px;
}

.sidebar_sortable_container {
  width: 270px;

  .MuiListSubheader-root .MuiTypography-root {
    color: $theme_color2;
  }

}

div.MuiInput-underline:after {
  border-bottom-color: $theme_color2;
}

button.MuiPickersDay-current {
  //color: $theme_color2;
}

.order_container .MuiTableHead-root {

  .MuiTableSortLabel-root:hover {
    color: $theme_color2;
  }


}

.modal_catalog_container.MuiDialog-scrollPaper {
  @include lgUp {
    @include align-items(flex-start);
  }
}

.modal_catalog.MuiPaper-root {
  background-color: hsla(0, 0%, 100%, .92);

  @include lgUp {
    margin-top: 64px;
  }

}

.order_dialog {
  .dialog_title {
    //padding-bottom: $spacing;

    @include mdDown {
      padding-left: $spacing;
      padding-right: $spacing;
    }

  }

  .header_container {
    @include align-items(center);
    @include justify-content(center);
  }

  .close {

    .MuiSvgIcon-root {
      border-radius: 50%;
      background-color: $border_gray_color2;
      color: #fff;
    }

  }

  .textField {
    margin-top: $spacing;
  }

  .MuiGrid-spacing-xs-2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .MuiDialogContent-root {
    padding-top: 0;
    padding-bottom: 16px;

    @include mdDown {
      padding-left: $spacing;
      padding-right: $spacing;
    }
  }

  .custom_autocomplete_container {
    margin-top: -$spacing;
  }

  .return_delivery_sum {
    margin-top: $spacing;
  }

}


.scroll_to_top {
  right: 11px;
  bottom: 16px;
  position: fixed;
  z-index: 999;
}

.select_language,
.select_currency {

  .MuiSelect-select.MuiSelect-select {
    padding-right: 20px;
  }

  .MuiSelect-icon {
    right: -8px;
  }


}

.select_currency {

  .MuiSelect-select {


    .MuiSvgIcon-root {
      font-size: 0.75rem;
    }
  }

}

.products_new_table_columns{
  //.discountLabelHolder {
  //  padding: 2px 6px!important;
  //}
  .discountLabel {
    font-size: 10px!important;

  }
}

.MuiAutocomplete-paper > ul {
  max-height: 34vh !important;
}


