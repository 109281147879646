.tab_panel#simple-tabpanel-0[tabactive="none"] {
  display: block;
}

.tab_panel:not([id=simple-tabpanel-0])[tabactive="active"] {
  z-index: 2;
  $tab_button_h: 45.8px;
  position: absolute;
  width: calc(100% / 12 * 7);
  top: $tab_button_h;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% - #{$tab_button_h});
  background-color: fade_out(#fff, 0.08);
}

.block_type_button_container .MuiIconButton-root .MuiSvgIcon-root.active {
  color: $theme_color2;
}

.sortable_popover .Mui-selected {
  color: $theme_color2;
}

.MuiPickersBasePicker-container .MuiPickersDay-daySelected {

  &,
  &:hover {
    background-color: $theme_color2;
  }

}

.tree_catalog > .MuiListItem-container .MuiListItem-root.selectIdItem {
    padding: 6px 8px 0 8px;


  .MuiTypography-root {
    color: $theme_color2;
    //font-size: 16px;
  }

}

.selectIdItem {
  a {
    padding: 10px 8px 0;
  }
}

.tree_catalog > .MuiListItem-root.selectIdItem {

  .MuiTypography-root {
    color: $theme_color2;
    font-size: 16px;
  }

}