.my_numeric_input {

  $height_input: 28px;

  @mixin border_input() {
    border: 1px solid$border_gray_color2;
  }

  @mixin button_count() {
    //@include border_input;
    //background-color: #eee;
    min-width: 0;
    height: $height_input;
    width: $height_input;
    //color: #555;
    text-align: center;
    //font-size: 16px;
    padding: 0;

    &:hover {
      //background-color: #ddd;
      cursor: pointer;
      //@include border_input;
    }

  }

  .stAdd {
    margin-left: 2px;
    @include button_count;
  }

  .stDel {
    margin-left: 2px;
    @include button_count;
  }

  .stInput {
    height: $height_input;
    @include border_input;
    color: #555;
    width: 40px;
    text-align: center;
    font-size: 16px;
    maxLength: 4;
    margin-left: 2px;
    padding: 0;

    &:hover {
      @include border_input;

    }

  }


  .MuiSvgIcon-root {
    width: $spacing*2;
  }

  //.stDel {
  //  margin-left: 0;
  //}

  .MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child).stInput,
  .MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child).stAdd {
    margin-left: 2px;
  }

  .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child).stInput {
    border-right-color:$border_gray_color2;
  }

  .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child).stDel {
    border-right-color:$border_gray_color2;
  }


}
